  
  .enlarge-image {
    position: relative; /* Set the position to relative */
    z-index: 1;
    width: 200px;
    height: 50px;
    
    /* margin:2%; */
    /* margin-top: 2%; */
    margin-right: 4%;
    transition: all 0.2s ease-in-out;
  }
  
  .enlarge-image:hover {
    /* right: -1%;
    top: -1%; */
    z-index: 2;
    /* height: 100px;
    width: 400px; */
    /* margin-left: 15%;
    margin-right: 80%; */
    transform: scale(1.5);
  }

  .menu-item {
    /* margin: 2%; */
    /* margin-right: 10px; */
    list-style-type: none;
  }
  
  #categorySelect{
    align-items: center;
  }

 
  